import React from 'react';
import './Card.css'

const Card = () => {
    return (
        <ul className="cards">
            <li>
                <a href="/" className="card">
                    <img src="https://media.geeksforgeeks.org/wp-content/cdn-uploads/20200214165928/Web-Development-Course-Thumbnail.jpg" className="card__image" alt="" />
                    <div className="card__overlay">
                        <div className="card__header">

                            <img className="card__thumb" src="https://media.geeksforgeeks.org/wp-content/cdn-uploads/20200214165928/Web-Development-Course-Thumbnail.jpg" alt="" />
                            <div className="card__header-text">
                                <h3 className="card__title">Web Course</h3>
                            </div>
                        </div>
                        <p className="card__description">Details</p>
                    </div>
                </a>
            </li>
            <li>
                <a href="/" className="card">
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjdcW0-r8leYMrneD2p8cVKju--VUnPbwLUqUD5L3urg2OJ_OLmTzHHZ7hBfGZJIUfnqw&usqp=CAU" className="card__image" alt="" />
                    <div className="card__overlay">
                        <div className="card__header">

                            <img className="card__thumb" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjdcW0-r8leYMrneD2p8cVKju--VUnPbwLUqUD5L3urg2OJ_OLmTzHHZ7hBfGZJIUfnqw&usqp=CAU" alt="" />
                            <div className="card__header-text">
                                <h3 className="card__title">React Course</h3>
                            </div>
                        </div>
                        <p className="card__description">Details</p>
                    </div>
                </a>
            </li>
            <li>
                <a href="/" className="card">
                    <img src="https://cdn.gabrieleromanato.com/5c37214980b3/uploads/2018/09/nodejs.jpg" className="card__image" alt="" />
                    <div className="card__overlay">
                        <div className="card__header">

                            <img className="card__thumb" src="https://cdn.gabrieleromanato.com/5c37214980b3/uploads/2018/09/nodejs.jpg" alt="" />
                            <div className="card__header-text">
                                <h3 className="card__title">Node Js </h3>

                            </div>
                        </div>
                        <p className="card__description">Details</p>
                    </div>
                </a>
            </li>
        </ul>
    );

}

export default Card;