import React, { Component } from 'react';
import * as Actions from '../../constants/baseUrl'
import MaterialReactTable from 'material-react-table'
import { MenuItem } from '@mui/material';
import axios from 'axios';
import './style.css'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-bootstrap/Modal'


export default class Institutes extends Component {
    static displayName = Institutes.name;

    entities = "institutes";
    
    constructor(props) {
        super(props);
        this.state = { items: [], loading: true,isForm:false };
    }
    
    componentDidMount() {
        this.populateList();
    }
     deleteItem(module,id){
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to delete?',
            buttons: [
              {
                label: 'Yes',
                class:'btn btn-info',
                    onClick: () => {
                        axios.delete(`${Actions.BASE_URL}${module}/${id}`).then(res =>
                        {
                            this.populateList();
                        });
                }
              },
              {
                label: 'No',
                //onClick: () => alert('Click No')
              }
            ]
          });
     } 
     deleteItem(id){
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to delete?',
            buttons: [
              {
                label: 'Yes',
                class:'btn btn-info',
                onClick: () => {
                    axios.delete(`${Actions.BASE_URL}${this.entities}/${id}`).then(res=>
                        {
                            this.populateList();
                        });
                }
              },
              {
                label: 'No',
                //onClick: () => alert('Click No')
              }
            ]
          });
     }    
    itemList(items) {
        var columns=[
            {
                accessorKey:'name',
                header:'Name'
            },
            {
                accessorKey:'type',
                header:'Type'
            },
            {
                accessorKey:'email',
                header:'Email'
            },
            {
                accessorKey:'phone',
                header:'Phone'
            }
        ];
        return (   
            <div> 
            <div className="col-lg-2 float-end">
                    <input type="button" value="Add" className="form-control btn btn-info" onClick={event => window.location.href ='/' +this.entities+'/add'}></input>
            </div>
            <div className='col-lg-12 row'>
            {items.map(item =>
                <div className='col-lg-4 mt-2'>
                    <div className='divheader col-lg-12 no-padding'>
                        <span>{item.name}</span>
                        <span className='text-end float-end offset-sm-1'><a onClick={event => window.location.href = '/' + this.entities + '/edit/' + item.id}><i className='fa fa-edit'></i></a></span>
                        <span className='text-end float-end'><a onClick={() => this.deleteItem(item.id)}><i className='fa fa-trash'></i></a></span>
                    </div>
                    <div className='col-lg-12 divbody'>
                        <div className='content'>
                        <p>{item.address}</p>
                        <p>Phone <i className='fa fa-phone'></i> :{item.phone} </p>
                        <p>Email <i className='fa fa-envelope'></i> : {item.email}</p>
                        </div>
                        <div className='col-lg-12 divfooter no-padding hide'>
                        <div className='col-lg-2 float-start text-center row divaction'>
                            <a href='#' onClick={()=>{this.openTrainerModel(item);}} className='action' title="trainers"><i className='fa fa-users'></i></a>
                            </div>
                            <div className='col-lg-2 flaot-start text-center row divaction'>
                            <a href='#' className='action' title="courses" onClick={()=>{this.openCourseModel(item);}}><i className='fa fa-book'></i></a>
                            </div>
                    </div>
                    </div>
                    
                </div>
                
            )
        }
</div>
        
    

                {/* <div className='col-lg-12 instituelist'>
<MaterialReactTable columns={columns} data={items} 
 enableRowActions
 positionActionsColumn="last"
 renderRowActionMenuItems={({ row }) => [
     <MenuItem key="detail" onClick={event => window.location.href ='/'+this.entities+'/details/'+row.original.id}>
     Details
     </MenuItem>,
     <MenuItem key="edit" onClick={event => window.location.href = '/' + this.entities + '/edit/' + row.original.id}>
     Edit
   </MenuItem>,
   <MenuItem key="delete" onClick={() => this.deleteItem(row.original.id)}>
     Delete
   </MenuItem>,
 ]}
    />
            {}
            </div> */}
            </div>       
        );
    }

    render() {
        let contents =!this.state.isForm?(this.state.loading
            ? <p><em>Loading... Please refresh once the Content is fetched.</em></p>
            : this.itemList(this.state.items)):this.reviewForm();

        return (
            <div>               
                <div>
                    <div className="container" >
                        <div className="offset-sm-1 col-lg-10">
                            <h2 id="tabelLabel" >Ameerpet IT - Institutes</h2>
                            <p>Check out the review about the courses, trainers and training institutes.</p>
                            <div className='col-lg-12'>
                            {contents}
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.isTrainerOpen ? 
          this.gettrainermodel()
          : 
          null 
        }
           { this.state.isCourseOpen ? 
          this.getcoursemodel()
          : 
          null 
        }
            </div>
        );
    }
    state = {
        isTrainerOpen: true,
        isCourseOpen:true,
        trainers:[],
        courses:[]
      }
    
      openTrainerModel (item){
     let ids=[];
     Object.keys(item.trainers).forEach(function(key) {
         ids.push(key);
     });
       
         
     axios.post(`${Actions.BASE_URL}trainers/GetItems`,ids).then(res =>
         {
            console.log(res.data);
            this.setState({ isCourseOpen: false,isTrainerOpen:true });
            this.setState({trainers:res.data});
         });
      }
      openCourseModel(item){ 
        
        let ids=[];
        Object.keys(item.courses).forEach(function(key) {
            ids.push(key);
        });
          
            
        axios.post(`${Actions.BASE_URL}courses/GetItems`,ids).then(res =>
            {
               console.log(res.data);
               this.setState({ isCourseOpen: true,isTrainerOpen:false });
               this.setState({courses:res.data});
            });
    }
      closeModal(){
        this.setState({ isTrainerOpen: false,isCourseOpen:false });
    }
    async populateList() {

        try {
            const data = await fetch(`${Actions.BASE_URL}${this.entities}`).then(response => {
                console.log(response);
                return response.json()
            });
            this.setState({ items: data, loading: false });
        } catch (error) {
            // TypeError: Failed to fetch
            console.log('There was an error', error);
        }
    }
    gettrainermodel(){
        var columns=[
            {
                accessorKey:'name',
                header:'Name'
            },
            {
                accessorKey:'email',
                header:'Email'
            },
            {
                accessorKey:'phone',
                header:'Phone'
            }
        ];
    return(
      <Modal 
        show={this.state.isTrainerOpen} 
        onHide={()=>this.closeModal()}
        className='modal-lg'
      >
      <Modal.Header closeButton>
        <Modal.Title>Trainers</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <MaterialReactTable columns={columns} data={this.state.trainers} 
 enableRowActions
 positionActionsColumn="last"
 renderRowActionMenuItems={({ row }) => [
     <MenuItem key="detail" onClick={event => window.location.href ='/trainers/details/'+row.original.id}>
     Details
     </MenuItem>,
     <MenuItem key="edit" onClick={event => window.location.href = '/trainers/edit/' + row.original.id}>
     Edit
   </MenuItem>,
   <MenuItem key="delete" onClick={() => this.deleteItem('trainers',row.original.id)}>
     Delete
   </MenuItem>,
 ]}
    />
      </Modal.Body>
      <Modal.Footer>
          <button className='btn btn-primary' type="button" onClick={() =>  this.setState({ isTrainerOpen: false })}>
              Close
          </button>
      </Modal.Footer>
    </Modal>
    )
  
    }
    getcoursemodel(){
        var columns=[
            {
                accessorKey:'name',
                header:'Name'
            },
            {
                accessorKey:'email',
                header:'Email'
            },
            {
                accessorKey:'phone',
                header:'Phone'
            }
        ];
        return(
          <Modal 
            show={this.state.isCourseOpen} 
            onHide={()=>this.closeModal()}
            className='modal-lg'
          >
          <Modal.Header closeButton>
            <Modal.Title>Courses</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <MaterialReactTable columns={columns} data={this.state.courses} 
 enableRowActions
 positionActionsColumn="last"
 renderRowActionMenuItems={({ row }) => [
     <MenuItem key="detail" onClick={event => window.location.href ='/courses/details/'+row.original.id}>
     Details
     </MenuItem>,
     <MenuItem key="edit" onClick={event => window.location.href = '/courses/edit/' + row.original.id}>
     Edit
   </MenuItem>,
   <MenuItem key="delete" onClick={() => this.deleteItem('courses',row.original.id)}>
     Delete
   </MenuItem>,
 ]}
    />
          </Modal.Body>
          <Modal.Footer>
              <button class="btn btn-primary" type="button" onClick={() =>  this.setState({ isCourseOpen: false })}>
                  Close
              </button>
          </Modal.Footer>
        </Modal>
        )
      
        }
}
