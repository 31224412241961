import React, { Component } from 'react';
import * as Actions from '../../constants/baseUrl'
import MaterialReactTable from 'material-react-table'
import { MenuItem } from '@mui/material';
import axios from 'axios';
import './style.css'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';



export default class Reviews extends Component {
    static displayName = Reviews.name;

    entities = "reviews";
    
    constructor(props) {
        super(props);
        this.state = { items: [], loading: true,isForm:false };
    }
    
    componentDidMount() {
        this.populateList();
    }
     deleteItem(id){
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to delete?',
            buttons: [
              {
                label: 'Yes',
                class:'btn btn-info',
                onClick: () => {
                    axios.delete(`${Actions.BASE_URL}${this.entities}/${id}`).then(res =>
                        {
                            this.populateList();
                        });
                }
              },
              {
                label: 'No',
                //onClick: () => alert('Click No')
              }
            ]
          });
     }    
    itemList(items) {
        var columns=[
            {
                accessorKey:'name',
                header:'Name'
            },
            {
                accessorKey:'type',
                header:'Type'
            },
            {
                accessorKey:'email',
                header:'Email'
            },
            {
                accessorKey:'phone',
                header:'Phone'
            }
        ];
        return (   
            <div> 
            <div className="col-lg-2 float-end">
                    <input type="button" value="Add" className="form-control btn btn-info" onClick={event => window.location.href ='/' +this.entities+'/add'}></input>
            </div>
                <div className='col-lg-12 instituelist'>
<MaterialReactTable columns={columns} data={items} 
 enableRowActions
 positionActionsColumn="last"
 renderRowActionMenuItems={({ row }) => [
     <MenuItem key="detail" onClick={event => window.location.href ='/'+this.entities+'/details/'+row.original.id}>
     Details
     </MenuItem>,
     <MenuItem key="edit" onClick={event => window.location.href = '/' + this.entities + '/edit/' + row.original.id}>
     Edit
   </MenuItem>,
   <MenuItem key="delete" onClick={() => this.deleteItem(row.original.id)}>
     Delete
   </MenuItem>,
 ]}
    />
            {}
            </div>
            </div>       
        );
    }

    render() {
        let contents =!this.state.isForm?(this.state.loading
            ? <p><em>Loading... Please refresh once the Content is fetched.</em></p>
            : this.itemList(this.state.items)):this.reviewForm();

        return (
            <div>               
                <div>
                    <div class="container" >
                        <div class="offset-sm-1 col-lg-10">
                            <h2 id="tabelLabel" >Ameerpet IT - Institutes</h2>
                            <p>Check out the review about the courses, trainers and training institutes.</p>
                            <div className='col-lg-12'>
                            {contents}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async populateList() {

        try {
            const data = await fetch(`${Actions.BASE_URL}${this.entities}`).then(response => {
                console.log(response);
                return response.json()
            });
            this.setState({ items: data, loading: false });
        } catch (error) {
            // TypeError: Failed to fetch
            console.log('There was an error', error);
        }
    }
}
