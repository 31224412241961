export const MenuData=[
  {
    title:"Home",
    Url:"/",
    cName:"nav-links",
    icon:"fa-solid fa-house-user"
  },
  {
    title:"Courses",
    Url:"/courses",
    cName:"nav-links",
    icon:"fa fa-list"
  },
  {
    title:"Trainers",
    Url:"/trainers",
    cName:"nav-links",
    icon:"fa fa-users"
  },  {
    title:"Institutes",
    Url:"/institutes",
    cName:"nav-links",
    icon:"fa fa-building"
  },  {
    title:"Reviews",
    Url:"/reviews",
    cName:"nav-links",
    icon:"fa fa-star"
  },
  {
    title:"Blogs",
    Url:"/blogs",
    cName:"nav-links",
    icon:"fa fa-blog"
  },
  {
    title:"Chat(AI)",
    Url:"/chat",
    cName:"nav-links",
    icon:"fa fa-message"
  },
  {
    title:"About",
    Url:"/about",
    cName:"nav-links",
    icon:"fa fa-person"
  },
  {
    title:"Contact",
    Url:"/contact",
    cName:"nav-links",
    icon:"fa fa-phone"
  }
]