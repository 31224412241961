import React from 'react'

import Slider from './Slider'
import Corses from './Corses'
import Navbar from '../headers/navbar'
import Review from './Review'
import Trainers from './Trainers'
import Footer from './Footer'
import Insitute from './Insitute'
import './home.css'
export default function Home() {
  return (
    <>
    <Slider/>
    <Corses/>
    <Review/>
    <Trainers/>
    <Insitute/>

    <Footer/>
    
    </>
  )
}
