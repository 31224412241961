import React from 'react'
import InsituteCards from './InsituteCards'



function Insitute() {

    
  return (
    <div>
        <br />
        <h1 className='heading'> Top Insitutes</h1>

      <InsituteCards/>
    </div>
  )
}

export default Insitute
