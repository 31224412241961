import React from 'react';
import * as Actions from '../../constants/baseUrl'
import axios from 'axios';
import "./C_add.css";

export default class Add_Course extends React.Component {
    entities = "courses";
    state = {
       name:"",
       phone:"",
       whatsapp:"",
       website:"",
       email:""
    }

    handleSubmit = event => {
        event.preventDefault();
        const item = this.state;
        console.log(item);


        axios.post(`${Actions.BASE_URL}${this.entities}`,  item )
            .then(res => {
               alert("data saved successfully!");
               window.location.href="/" + this.entities;
               this.setState({
                name:"",
                phone:"",
                whatsapp:"",
                email:"",
                website:""
               });
            })
    }
    resetfields(){
    this.setState({
        name:"",
        phone:"",
        whatsapp:"",
        email:"",
        website:""
    });
}
    render() {
        return (
<>
            <div className='body'>
                <br /><br />
          
            <h1 className='heading'>Add New Course</h1>
            <form className='form offset-sm-2 col-lg-6 row  mt-4 bg-dark' onSubmit={this.handleSubmit}>
    <div className='col-lg-6 col-md-6'>
        <label className='col-sm-8 col-form-label text-white'>Name</label>
        <div className='col-lg-12'>
            <input className='form-control bg-secondary text-white' name="name" defaultValue={this.state.name} onChange={(e) => this.setState({name:e.target.value})}></input>
        </div>
    </div>
    <div className='col-lg-6 col-md-6'>
        <label className='col-sm-8 col-form-label text-white'>Email</label>
        <div className='col-lg-12'>
            <input className='form-control bg-secondary text-white' name="email" defaultValue={this.state.email} onChange={(e) => this.setState({email:e.target.value})}></input>
        </div>
    </div>
    <div className='col-lg-6 col-md-6'>
        <label className='col-sm-8 col-form-label text-white'>Phone</label>
        <div className='col-lg-12'>
            <input className='form-control bg-secondary text-white' name="phone" defaultValue={this.state.phone} onChange={(e) => this.setState({phone:e.target.value})}></input>
        </div>
    </div>
    <div className='col-lg-6 col-md-6'>
        <label className='col-sm-8 col-form-label text-white'>Whatapp</label>
        <div className='col-lg-12'>
            <input className='form-control bg-secondary text-white' name="whatsapp" defaultValue={this.state.whatsapp} onChange={(e) => this.setState({whatsapp:e.target.value})}></input>
        </div>
    </div>
    <div className='col-lg-6 col-md-6'>
        <label className='col-sm-8 col-form-label text-white'>Website</label>
        <div className='col-lg-12'>
            <input className='form-control bg-secondary text-white' name="website" defaultValue={this.state.website} onChange={(e) => this.setState({website:e.target.value})}></input>
        </div>
    </div>
    <div className='row col-lg-12 mt-4'>
        <div className='offset-sm-2 col-sm-4 d-grid'>
            <button type='submit'  id="save" className='btn save btn-info  btn-sm me-3'>Save</button>
        </div>
        <div className='col-sm-4 d-grid'>
            <input type="reset"  value="Reset" className="btn cancel btn-secondary" onClick={()=>this.resetfields()}></input>
        </div>
    </div>
</form>
 </div>
 
</>
        )
    }
}
 