import React, { useState } from 'react';
import './Slider.css';
import SearchBar from './SearchBar';


function Slider() {
  const images = [
    'https://c.urbanpro.com/assets/new-ui/uphomepage/newHomePage-bcd049ce47914a3e9a548e426be83308.png',
    'https://www.wonderplugin.com/wp-content/uploads/2019/05/tutorial-computer-900x288.jpg',
    'https://jssors8.azureedge.net/demos/image-slider/img/px-fun-man-person-2361598-image.jpg',
    'https://jssors8.azureedge.net/demos/img/gallery/980x380/023.jpg'
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {

    setCurrentIndex(((currentIndex + 1) % images.length) % images.length);
  };

  return (
    <div>

      <div className="slider-container">
        <div className="slider-arrow left-arrow" onClick={handlePrev}>
          &#10094;
        </div>
        <div className="slider-arrow right-arrow" onClick={handleNext}>
          &#10095;

        </div>
        <div className="slider-search" >
          <b className='text' >Search   <b className='text2'>Courses,Insitutes...</b> </b>
          <h1></h1>
          <SearchBar />

        </div>
        <img src={images[currentIndex]} className="slider-image" alt="" />

      </div>
    </div>
  );
}

export default Slider;
