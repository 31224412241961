import React from "react";
import {MenuData} from "../headers/menudata"
import { Link } from "react-router-dom";
import './Footer.css'
function Footer() {
  return (
    <div classNAme="footer">
      <div className="footer-menu">
        <ul>
          {MenuData.map((item, index) => {
            return (
              <li key={index}>
                <Link to={item.Url} className={item.cName}>
                  <i className={item.icon}></i>
                  {item.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    
    </div>
  );
}

export default Footer;
