import React, { Component } from 'react';
import * as Actions from '../../constants/baseUrl'
import axios from 'axios';
import withRouter from '../../constants/withRouter';
import './style.css'
class Edit_Blog extends Component {

    entities = "blogs";

    state = {
       name:"",
       phone:"",
       whatsapp:"",
       website:"",
       email:""
      
    }
componentDidMount(){
    
    console.log('Props:', this.props)
   this.populateList(this.props.params.id);
}
    handleSubmit = event => {
        event.preventDefault();
        const item = this.state;
        console.log(item);


        axios.put(`${Actions.BASE_URL}${this.entities}/${item.id}`,  item )
            .then(res => {
               alert("data updated successfully!");
               window.location.href="/" + this.entities;
               this.setState({
                title:"",
                phone:"",
                whatsapp:"",
                email:"",
                website:""
               });
            })
    }
    resetfields(){
    this.setState({
        title:"",
        phone:"",
        whatsapp:"",
        email:"",
        website:""
    });
 }
    render() {
        return (
            <div className='container'>
                <form className='offset-sm-2 col-lg-8 row mt-4 boxstyle'  onSubmit={this.handleSubmit}>
                        <div className='col-lg-6 col-md-6'>
                            <label className='col-sm-8 col-form-label'>Name</label>
                            <div className='col-lg-12'>
                                <input className='form-control' name="title" defaultValue={this.state.title} onChange={(e) => this.setState({title:e.target.value})}></input>
                                <input type='hidden' className='form-control' name="id" defaultValue={this.state.id} ></input>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <label className='col-sm-8 col-form-label'>Email</label>
                            <div className='col-lg-12'>
                                <input className='form-control' name="email" defaultValue={this.state.email} onChange={(e) => this.setState({email:e.target.value})}></input>

                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <label className='col-sm-8 col-form-label'>Phone</label>
                            <div className='col-lg-12'>
                                <input className='form-control' name="phone" defaultValue={this.state.phone} onChange={(e) => this.setState({phone:e.target.value})}></input>

                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <label className='col-sm-8 col-form-label'>Whatsapp</label>
                            <div className='col-lg-12'>
                                <input className='form-control' name="whatsapp" defaultValue={this.state.whatsapp} onChange={(e) => this.setState({whatsapp:e.target.value})}></input>

                            </div>
                        </div>
                      
                        <div className='col-lg-6 col-md-6'>
                            <label className='col-sm-8 col-form-label'>Website</label>
                            <div className='col-lg-12'>
                                <input className='form-control' name="website" defaultValue={this.state.website} onChange={(e) => this.setState({website:e.target.value})}></input>

                            </div>
                        </div>
                        <div className='row col-lg-12 mt-4'>
                            <div className='offset-sm-2 col-sm-4 d-grid'>
                                <button type='submit' className='btn btn-info btn-sm me-3'>Save</button>
                            </div>
                            <div className='col-sm-4 d-grid'>
                            <input type="reset" value="Reset" className="btn btn-secondary" onClick={()=>this.resetfields()}></input>
                            </div>
                        </div>
                    </form>
              
            </div>
        )
    }
    async populateList(id) {

        try {
            const data = await fetch(`${Actions.BASE_URL}${this.entities}/${id}`).then(response => {
                console.log(response);
                return response.json()
            });
            console.log(data);

            this.setState(data);
        } catch (error) {
            // TypeError: Failed to fetch
            console.log('There was an error', error);
        }
    }
}

export default withRouter(Edit_Blog);
