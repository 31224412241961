import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LoginComponent from './components/login/loginform'
const mode = 'login';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
     
      {
        
        localStorage.getItem("isValidUser")==true||localStorage.getItem("isValidUser")=="true"?<App/>:<div className={`app app--is-${mode}`}><LoginComponent
        mode={mode}
        onSubmit={
            function(e) {
              if(e.currentTarget["username"].value="corporate@ameerpetit.com"&&e.currentTarget["password"].value=="@meerperIT123"){
                localStorage.setItem("isValidUser",true);
              }
              else{
                localStorage.setItem("isValidUser",false);

              }
            }
        }
        onChange={
          function(e){
console.log(e.target.value);
          }
        }
    />
   </div>

      }
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
