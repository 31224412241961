import React from 'react'
import './TrainerCards.css'
function TrainerCards() {
  return (
    <>
    
    <ul className="facilities-list">

<div className="con2">
<li className="wow fadeInUp first res" data-wow-delay="0.3s">
    <div className="facility-box">
        <div className="facility-box-head">
            <h3>Abhijeet Singh</h3>
       
        </div>
        <p>
        IT trainers design and deliver training courses in information technology. As an IT trainer, you'll need skills in the design and delivery of IT training 
        </p>
    </div>
</li>

<li className="wow fadeInUp res" data-wow-delay="0.5s" >
    <div className="facility-box">
        <div className="facility-box-head">
            <h3>Hassan Arif</h3>
           
        </div>
        <p>
        IT trainers design and deliver training courses in information technology. As an IT trainer, you'll need skills in the design and delivery of IT training 
        </p>
    </div>
</li>

<li className="wow fadeInUp res" data-wow-delay="0.7s" >
    <div className="facility-box">
        <div className="facility-box-head">
            <h3>Chandri Kumar</h3>

        </div>
        <p>
        IT trainers design and deliver training courses in information technology. As an IT trainer, you'll need skills in the design and delivery of IT training 
        </p>
    </div>
</li>

</div>


</ul>
    
    
    
    
    </>
  )
}

export default TrainerCards
