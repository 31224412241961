import React, { Component } from 'react';
import Navbar from './components/headers/navbar';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './components/Home/Home';

import Courses from './components/courses/getAll';
import CourseAdd from './components/courses/add';
import CourseDetails from './components/courses/details';
import CourseEdit from './components/courses/edit';

import Trainers from './components/trainers/getAll';
import TrainerAdd from './components/trainers/add';
import TrainerDetails from './components/trainers/details';
import TrainerEdit from './components/trainers/edit';


import Institutes from './components/institutes/getAll';
import InstituteAdd from './components/institutes/add';
import InstituteDetails from './components/institutes/details';
import InstituteEdit from './components/institutes/edit';

import Reviews from './components/reviews/getAll';
import ReviewAdd from './components/reviews/add';
import ReviewDetails from './components/reviews/details';
import ReviewEdit from './components/reviews/edit';

import Blogs from './components/blogs/getAll';
import BlogAdd from './components/blogs/add';
import BlogDetails from './components/blogs/details';
import BlogEdit from './components/blogs/edit';

import UploadFiles from './components/admin/fileupload';

import Contact from './components/contact/';

export default class App extends Component {
    static displayName = App.name;
    constructor(props) {
        super(props);
        this.state = { forecasts: [], loading: true };
    }

    componentDidMount() {
    }


    render() {

        return (
            <Router>
      <Navbar />
    
      <Routes>
        <Route path='/' exact element={<Home/>} />
        <Route path='/courses' element={<Courses />} />
        <Route path='/courses/details/:id' element={<CourseDetails />} />
        <Route path='/courses/add' element={<CourseAdd />} />
        <Route path='/courses/edit/:id' element={<CourseEdit />} />

                    <Route path='/trainers' element={<Trainers />} />
                    <Route path='/trainers/details/:id' element={<TrainerDetails />} />
                    <Route path='/trainers/add' element={<TrainerAdd />} />
                    <Route path='/trainers/edit/:id' element={<TrainerEdit />} />

        <Route path='/institutes' element={<Institutes />} />
        <Route path='/institutes/details/:id' element={<InstituteDetails />} />
        <Route path='/institutes/add' element={<InstituteAdd />} />
        <Route path='/institutes/edit/:id' element={<InstituteEdit />} />

                    <Route path='/reviews' element={<Reviews />} />
                    <Route path='/reviews/details/:id' element={<ReviewDetails />} />
                    <Route path='/reviews/add' element={<ReviewAdd />} />
                    <Route path='/reviews/edit/:id' element={<ReviewEdit />} />

                    <Route path='/blogs' element={<Blogs />} />
                    <Route path='/blogs/details/:id' element={<BlogDetails />} />
                    <Route path='/blogs/add' element={<BlogAdd />} />
                    <Route path='/blogs/edit/:id' element={<BlogEdit />} />

                    <Route path='/contact' element={<Contact />} />
                    <Route path='/admin/upload' element={<UploadFiles />} />

        </Routes>
    </Router>
    
        );
    }

  
}
