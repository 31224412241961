import React from 'react';
import * as Actions from '../../constants/baseUrl'
import axios from 'axios';
import './form.css'

export default class Add_CourseTrainer extends React.Component {
    entities = "trainers";
    state = {
        first_name: "",
        last_name: "",
        name: "",
        email: "",
        phone: "",
        whatsapp: "",
        website: "",
        country: "",
        state: "",
        city: "",
        linkedin: "",
        trainer_bio: "",
        years_experience: "",
        skillset_speciality: "",
        specialization: "",
        modeOfTraining: "",
        institutes_associated: "",
        corporate_experience: "",
        achievements: "",
        past_training_sessions: "",
        past_projects: "",
        proposed_plan_for_training: "",
        courses_offered: "",
        video_links: "",
        demo_className_slot: "",
        photo: ""
    }

    handleSubmit = event => {
        event.preventDefault();
        const item = this.state;
        console.log(item);

        axios.post(`${Actions.BASE_URL}${this.entities}`, item)
            .then(res => {
                alert("data saved successfully!");
                window.location.href = "/" + this.entities;
                this.setState({
                    first_name: "",
                    last_name: "",
                    name: "",
                    email: "",
                    phone: "",
                    whatsapp: "",
                    website: "",
                    country: "",
                    state: "",
                    city: "",
                    linkedin: "",
                    trainer_bio: "",
                    years_experience: "",
                    skillset_speciality: "",
                    specialization: "",
                    modeOfTraining: "",
                    institutes_associated: "",
                    corporate_experience: "",
                    achievements: "",
                    past_training_sessions: "",
                    past_projects: "",
                    proposed_plan_for_training: "",
                    courses_offered: "",
                    video_links: "",
                    demo_className_slot: "",
                    photo: ""
                });
            })
    }

    resetFields = () => {
        this.setState({
            first_name: "",
            last_name: "",
            name: "",
            email: "",
            phone: "",
            whatsapp: "",
            website: "",
            country: "",
            state: "",
            city: "",
            linkedin: "",
            trainer_bio: "",
            years_experience: "",
            skillset_speciality: "",
            specialization: "",
            modeOfTraining: "",
            institutes_associated: "",
            corporate_experience: "",
            achievements: "",
            past_training_sessions: "",
            past_projects: "",
            proposed_plan_for_training: "",
            courses_offered: "",
            video_links: "",
            demo_className_slot: "",
            photo: ""
        });
    }

    render() {
        return (
            
            <div className='container'>
                <form className='offset-sm-2 col-lg-8 row boxstyle mt-4'  onSubmit={this.handleSubmit}>
                        <div className='col-lg-6 col-md-6'>
                            <label className='col-sm-8 col-form-label'>Name</label>
                            <div className='col-lg-12'>
                                <input className='form-control' name="name" defaultValue={this.state.name} onChange={(e) => this.setState({name:e.target.value})}></input>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <label className='col-sm-8 col-form-label'>Email</label>
                            <div className='col-lg-12'>
                                <input className='form-control' name="email" defaultValue={this.state.email} onChange={(e) => this.setState({email:e.target.value})}></input>

                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <label className='col-sm-8 col-form-label'>Phone</label>
                            <div className='col-lg-12'>
                                <input className='form-control' name="phone" defaultValue={this.state.phone} onChange={(e) => this.setState({phone:e.target.value})}></input>

                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <label className='col-sm-8 col-form-label'>Whatapp</label>
                            <div className='col-lg-12'>
                                <input className='form-control' name="whatsapp" defaultValue={this.state.whatsapp} onChange={(e) => this.setState({whatsapp:e.target.value})}></input>

                            </div>
                        </div>
                       
                        <div className='col-lg-6 col-md-6'>
                            <label className='col-sm-8 col-form-label'>Website</label>
                            <div className='col-lg-12'>
                                <input className='form-control' name="website" defaultValue={this.state.website} onChange={(e) => this.setState({website:e.target.value})}></input>

                            </div>
                        </div>
                        <div className='row col-lg-12 mt-4'>
                            <div className='offset-sm-2 col-sm-4 d-grid'>
                                <button type='submit' className='btn btn-info btn-sm me-3'>Save</button>
                            </div>
                            <div className='col-sm-4 d-grid'>
                            <input type="reset" value="Reset" className="btn btn-secondary" onClick={()=>this.resetfields()}></input>
                            </div>
                        </div>
                    </form>
              
            </div>
        )
    }
}

