import React from "react";
import './ReviewCard.css';

const ReviewCard = () => {
  return (
<>

<h1 className='heading'> Top Reviews</h1>
<br />
    <div className="ReviewCards">



   
          <div className="reward-item">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCZMSvojI5Uql0S0hPr8UUyTdXVoDFUqLjMQ&usqp=CAU" alt="Reward 1" />
            <h3>Top Courses</h3>
            <p><b style={{color: "#dc3545" ,}}>Java , C , C++</b>  Courses </p>

            <div class="rating">
  <span class="star">&#9733;</span>
  <span class="star">&#9733;</span>
  <span class="star">&#9733;</span>
  <span class="star">&#9733;</span>
  <span class="star">&#9734;</span>
</div>
          </div>

          <div className="reward-item">
            <img src="https://www.zdnet.com/a/img/resize/adcb2eb2cc3f5562f7e80931308e2a08b01947ce/2019/08/19/4a663776-f4a9-4f89-9bee-2d07ee052f5b/istock-1147195672-11.jpg?auto=webp&fit=crop&height=900&width=1200" alt="Reward 2" />
            <h3>Top Tainers</h3>
            <p><b style={{color: "#dc3545" ,}}>Karan , Abhishek </b>  Trainers </p>

            <div class="rating">
  <span class="star">&#9733;</span>
  <span class="star">&#9733;</span>
  <span class="star">&#9733;</span>
  <span class="star">&#9733;</span>
  <span class="star">&#9734;</span>
</div>
          </div>

          <div className="reward-item">
            <img src="https://www.digitalit-inst.com/images/logo-wide.png" alt="Reward 3" />
            <h3>Top Insitutes</h3>
            <p> <b style={{color: "#dc3545" ,}}>Cs , Web , C++</b> Institutes  </p>

            <div class="rating">
  <span class="star">&#9733;</span>
  <span class="star">&#9733;</span>
  <span class="star">&#9733;</span>
  <span class="star">&#9733;</span>
  <span class="star">&#9734;</span>
</div>
          </div>
        </div>

 

    </>
  );
};

export default ReviewCard;
