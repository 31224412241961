import React from 'react';
import './InsituteCard.css'
function InstituteCards() {
  return (
    <div className="ag-format-container">
      <div className="ag-courses_box">
        <div className="ag-courses_item">
          <div className="ag-courses-item_link">
            <div className="ag-courses-item_bg"></div>

            <div className="ag-courses-item_title">
            Insitute 1
            </div>

            <div className="ag-courses-item_date-box">
            If you have been looking for a detailed WordPress Customization Course in a top-notch institute in Dubaies, you need to look no further than PNY Trainings
            </div>
          </div>
        </div>

        <div className="ag-courses_item">
          <div className="ag-courses-item_link">
            <div className="ag-courses-item_bg"></div>

            <div className="ag-courses-item_title">
            Insitute 2
            </div>

            <div className="ag-courses-item_date-box">
            If you have been looking for a detailed WordPress Customization Course in a top-notch institute in Dubaies, you need to look no further than PNY Trainings
            </div>
          </div>
        </div>

        <div className="ag-courses_item">
          <div className="ag-courses-item_link">
            <div className="ag-courses-item_bg"></div>

            <div className="ag-courses-item_title">
            Insitute 3
            </div>

            <div className="ag-courses-item_date-box">
            If you have been looking for a detailed WordPress Customization Course in a top-notch institute in Dubaies, you need to look no further than PNY Trainings
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstituteCards;
