import React from 'react'
import Card from './Card'


function Corses() {
  return (
    <>
<br />
<h1 className='heading'>Upcoming Courses</h1>
      <Card />
     
    </>
  )
}

export default Corses
