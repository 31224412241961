import React, { Component } from "react";
import "./navbarstyle.css"
import {MenuData} from "./menudata"
import {  Link } from "react-router-dom";
import logo from "../../AMPTITLogog.jpg";
class Navbar extends Component {

  render() {
    return (
     <nav className="NavbarItems">
      <>


      <img src={logo} alt="" className="logstyle" width={190} height={70}/>

      </>
      <ul className="nav-menu">
        {MenuData.map((item,index)=>{
          return(
            <li key={index}>
            <Link to={item.Url} className={item.cName}>
              <i className={item.icon}></i>{item.title}
            </Link>
          </li>
          )
        })}
   
      </ul>
     </nav>
    );
  }
}

export default Navbar;