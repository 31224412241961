import React from 'react'
import TrainerCards from './TrainerCards'

function Trainers() {
  return (

    <div>
<br />
<h1 className='heading'> Top Trainers</h1>
<br />
     <TrainerCards/>
    </div>
  )
}

export default Trainers
