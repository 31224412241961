import React from 'react'
import ReviewCard from './ReviewCard'

function Review() {
  return (
    <div>
      
      <ReviewCard/>

    </div>
  )
}

export default Review
