import React, { Component } from 'react';
import * as Actions from '../../constants/baseUrl'
import axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css";
import './admin.css'
import Alert from 'react-bootstrap/Alert';


export default class UploadFiles extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedFiles: null,
      currentFile: null,
      message: "",
      base64URL: "",
      file: null,
      Module: ""
    };
  }
  componentDidMount() {

  }
  selectFile = (event) => {
    this.setState({ selectedFiles: event.target.files, currentFile: event.target.files[0] });
    let file = event.target.files[0];
    this.getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          base64URL: result,
          file
        });
      })
  }
  getBase64(file) {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result.replace('data:text/csv;base64,', '');
        console.log('64' + baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  handleFileInputChange = () => {
    var req = {
      Type: this.state.Module,
      FileContent: this.state.base64URL
    }
    axios.post(Actions.BASE_URL + "Upload", req).then(res => {
      this.setState({message:"success"});
      setTimeout(()=>{
        this.setState({message:""});
      },2000);
    })
      .catch(err => {
        console.log(err);
      });

  };
  render() {
    const {
      selectedFiles,
      currentFile,
      message
    } = this.state;

    return (
      <>

        <div className='container col-lg-6'>
        {
          this.state.message != "" ?
            <Alert key="success" variant="success">
              Data imported successfully !
            </Alert> : ""
      
  }
          <div className='col-lg-6 fileuploadbox offset-sm-3'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <select name="type" id="type" className='form-control' onChange={(e) => { this.setState({ Module: e.target.value }) }}>
                <option value="" selected>Select Module </option>
                <option value="Course">Course</option>
                <option value="Trainer">Trainer</option>
                <option value="Institute">Institute</option>
                <option value="Review">Review</option>
                <option value="Blog">Blog</option>
              </select>
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 mt-3'>
              <input type="file" onChange={this.selectFile} className='form-control' />
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 mt-3'>
              <div className='col-lg-12'>
                <button className="btn form-control btn-success mt-3 float-start"
                  disabled={!selectedFiles}
                  onClick={this.handleFileInputChange}
                >
                  Upload
                </button>
              </div>
            </div>


          </div>
        </div>
      </>
    );
  }
}