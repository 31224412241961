import React, { useState } from 'react';
import './SearchBar.css';

function SearchBar() {
  const [query, setQuery] = useState('');


  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleClick = (event) => {
    event.preventDefault();
   
  };

  return (
    <div className="form-container">
       
        <input type="text" value={query} onChange={handleInputChange} placeholder="" />
        <button onClick={handleClick}>Search</button>
      </div>
  );
}

export default SearchBar;
